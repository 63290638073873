import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { ICreateZoneValidationState } from "../../../pages/devices/zones-setup-page/hooks/useZoneFormValidation";
import { GoToPTHandlerType } from "../../hooks/usePTController";
import CircleArrowDown from "../icons/CircleArrowDown";
import CircleArrowLeft from "../icons/CircleArrowLeft";
import CircleArrowRight from "../icons/CircleArrowRight";
import CircleArrowUp from "../icons/CircleArrowUp";

type ControlTableProps = {
  pan: string;
  tilt: string;
  zoom: string;
  validation?: ICreateZoneValidationState;
  showValidationAlert?: boolean;
  ptDisabled?: boolean;
  hasZoom?: boolean;
  movePtUp: () => void;
  movePtDown: () => void;
  movePtLeft: () => void;
  movePtRight: () => void;
  handleGoToPt?: GoToPTHandlerType;
  handlePanInputChange: (pan?: string) => void;
  handleTiltInputChange: (tilt?: string) => void;
  handleZoomInputChange: (zoom?: string) => void;
};

const PTZ_MIN_WIDTH = "74px";

const PTZ_MAX_WIDTH = "78px";

const ControlTable: React.FC<ControlTableProps> = ({
  pan,
  tilt,
  zoom,
  validation,
  showValidationAlert,
  handleGoToPt,
  handlePanInputChange,
  handleTiltInputChange,
  handleZoomInputChange,
  movePtDown,
  movePtLeft,
  movePtRight,
  movePtUp,
  hasZoom,
  ptDisabled,
}): JSX.Element => {
  const goToPtHandler = (): void => {
    if (!handleGoToPt) return;

    handleGoToPt(pan, tilt, zoom);
  };

  const handleMovePtDown = (): void => {
    if (!movePtDown) return;

    movePtDown();
  };

  const handleMovePtUp = (): void => {
    if (!movePtUp) return;

    movePtUp();
  };

  const handleMovePtLeft = (): void => {
    if (!movePtLeft) return;

    movePtLeft();
  };

  const handleMovePtRight = (): void => {
    if (!movePtRight) return;

    movePtRight();
  };

  return (
    <>
      <Table size="small" sx={{ width: "240px" }}>
        <TableBody>
          <TableRow
            sx={{
              "& td": {
                border: 0,
                padding: 0,
              },
              "& .small": {
                width: "32px",
                height: "32px",
              },
              "& .pan": {
                paddingLeft: ".75em",
              },
            }}
          >
            <TableCell className="small"></TableCell>
            <TableCell className="small">
              <CircleArrowUp
                disabled={ptDisabled}
                width={32}
                height={32}
                fontSize="large"
                onClick={handleMovePtUp}
              />
            </TableCell>
            <TableCell className="small"></TableCell>
            <TableCell rowSpan={2} className="pan">
              <Typography
                variant="inputLabel"
                color="otherTextTertiary.main"
                component="div"
              >
                Pan
              </Typography>
              <TextField
                type="text"
                error={validation?.pan.hasError}
                helperText={validation?.pan.errorMessage}
                InputProps={{ sx: { borderRadius: "8px" } }}
                sx={{
                  minWidth: PTZ_MIN_WIDTH,
                  maxWidth: PTZ_MAX_WIDTH,
                  "& input": {
                    borderRadius: "8px",
                    height: "18px",
                    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                  },
                }}
                id="pan"
                size="small"
                variant="outlined"
                margin="dense"
                disabled={ptDisabled}
                value={
                  pan.includes(".") ? pan.slice(0, pan.indexOf(".") + 3) : pan
                }
                onChange={(e): void => handlePanInputChange(e.target.value)}
              />
            </TableCell>
            <TableCell rowSpan={2} className="pan">
              <Typography
                variant="inputLabel"
                color="otherTextTertiary.main"
                component="div"
              >
                Tilt
              </Typography>
              <TextField
                type="text"
                error={validation?.tilt.hasError}
                helperText={validation?.tilt.errorMessage}
                InputProps={{ sx: { borderRadius: "8px" } }}
                sx={{
                  minWidth: PTZ_MIN_WIDTH,
                  maxWidth: PTZ_MAX_WIDTH,
                  "& input": {
                    height: "18px",
                    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                  },
                }}
                id="tilt"
                size="small"
                variant="outlined"
                margin="dense"
                disabled={ptDisabled}
                value={
                  tilt.includes(".")
                    ? tilt.slice(0, tilt.indexOf(".") + 3)
                    : tilt
                }
                onChange={(e): void => handleTiltInputChange(e.target.value)}
              />
            </TableCell>
            <TableCell rowSpan={2} className="pan">
              <>
                {hasZoom && (
                  <>
                    <Typography
                      variant="inputLabel"
                      color="otherTextTertiary.main"
                      component="div"
                    >
                      Zoom
                    </Typography>
                    <TextField
                      type="text"
                      error={validation?.zoom.hasError}
                      helperText={validation?.zoom.errorMessage}
                      InputProps={{ sx: { borderRadius: "10px" } }}
                      sx={{
                        minWidth: PTZ_MIN_WIDTH,
                        maxWidth: PTZ_MAX_WIDTH,
                        "& input": {
                          height: "18px",
                          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                        },
                      }}
                      id="zoom"
                      size="small"
                      variant="outlined"
                      margin="dense"
                      disabled={ptDisabled}
                      value={zoom}
                      onChange={(e): void =>
                        handleZoomInputChange(e.target.value)
                      }
                    />
                  </>
                )}
              </>
            </TableCell>
          </TableRow>

          <TableRow
            sx={{
              "& td": {
                border: 0,
                padding: 0,
              },
              "& .small": {
                width: "32px",
                height: "32px",
              },
            }}
          >
            <TableCell className="small">
              <CircleArrowLeft
                disabled={ptDisabled}
                width={32}
                height={32}
                fontSize="large"
                onClick={handleMovePtLeft}
              />
            </TableCell>
            <TableCell className="small"></TableCell>
            <TableCell className="small">
              <CircleArrowRight
                disabled={ptDisabled}
                width={32}
                height={32}
                fontSize="large"
                onClick={handleMovePtRight}
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow
            sx={{
              "& td": {
                border: 0,
                padding: 0,
              },
              "& .small": {
                width: "32px",
                height: "32px",
              },
              "& .pan": {
                paddingLeft: ".75em",
              },
            }}
          >
            <TableCell className="small"></TableCell>
            <TableCell className="small">
              <CircleArrowDown
                disabled={ptDisabled}
                width={32}
                height={32}
                fontSize="large"
                onClick={handleMovePtDown}
              />
            </TableCell>
            <TableCell className="small"></TableCell>
            <TableCell colSpan={3} className="pan">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip
                  title={
                    !pan || !tilt || !zoom || ptDisabled
                      ? "Please fill out all required fields"
                      : ""
                  }
                >
                  <div style={{ width: "100%" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={!pan || !tilt || !zoom || ptDisabled}
                      onClick={goToPtHandler}
                      size="small"
                    >
                      Apply
                    </Button>
                  </div>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {showValidationAlert && (
        <>
          {validation?.panValue.hasError && (
            <Box
              sx={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              <Alert severity="error">
                {validation?.panValue.errorMessage}
              </Alert>
            </Box>
          )}
          {validation?.tiltValue.hasError && (
            <Box>
              <Alert severity="error">
                {validation?.tiltValue.errorMessage}
              </Alert>
            </Box>
          )}
          {validation?.zoomValue.hasError && (
            <Box>
              <Alert severity="error">
                {validation?.zoomValue.errorMessage}
              </Alert>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ControlTable;
